import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Slider from 'react-slick'
import PriceCard from './PriceCard'

const itemsBasic = [
  '5.000 Minutos de almacenamiento',
  '25.000 minutos de visualización',
  'Hasta 100 usuarios',
  '0.50€ por subscripción',
]
const itemsPro = [
  '7.500 Minutos de almacenamiento',
  '100.000 minutos de visualización',
  'Hasta 500 usuarios',
  '0.50€ por subscripción ',
]

const itemsCustom = ['Un plan a medida hecho para ti', 'Ponte en contacto con nuestro equipo de ventas', '<br><br>']

const plans = {
  monthly: {
    basic: {
      price: '99€',
    },
    pro: {
      price: '299€',
    },
    custom: {
      price: '- €',
    },
  },
  yearly: {
    basic: {
      price: '89€',
    },
    pro: {
      price: '269€',
    },
    custom: {
      price: '- €',
    },
  },
}

export default function PricePlan({ id, viewport }) {
  const router = useRouter()
  const [btnPlan, setBtnPlan] = useState('monthly')
  const [currentPlan, setCurrentPlan] = useState(plans.monthly)
  const [settings, setSettings] = useState({
    dots: true,
    infinite: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
  })

  const handleClickPlan = plan => {
    setBtnPlan(plan)
    if (plan === 'monthly') {
      setCurrentPlan(plans.monthly)
    } else {
      setCurrentPlan(plans.yearly)
    }
  }

  return (
    <React.Fragment>
      {viewport === 'desktop' ? (
        <div id={id} className={'w-full flex flex-wrap justify-center price-plan'}>
          <p className={'w-full flex justify-center title'}>{router.locale === 'es' ? 'Un plan perfecto pensado para ti' : 'A perfect plan designed for you'}</p>
          <div className={'w-full flex flex-wrap plan-type'}>
            <button
              className={`btn-plan monthly ${btnPlan === 'monthly' ? 'active' : ''}`}
              onClick={() => handleClickPlan('monthly')}
            >
              MENSUAL
            </button>
            <button
              className={`btn-plan yearly ${btnPlan === 'yearly' ? 'active' : ''}`}
              onClick={() => handleClickPlan('yearly')}
            >
              ANUAL
            </button>
          </div>
          <div className={'w-full flex flex-wrap justify-center plan-cards-content'}>
            <PriceCard
              type={'normal'}
              title={'Basico'}
              price={currentPlan.basic.price}
              planUnit={'/ Mes'}
              planItems={itemsBasic}
              btnTitle={'PRUÉBALO AHORA'}
              viewport={viewport}
            />
            <PriceCard
              type={'main'}
              title={'Profesional'}
              price={currentPlan.pro.price}
              planUnit={'/ Mes'}
              planItems={itemsPro}
              btnTitle={'PRUÉBALO AHORA'}
              viewport={viewport}
            />
            <PriceCard
              type={'normal'}
              title={'Enterprise'}
              price={currentPlan.custom.price}
              planUnit={'/ Mes'}
              planItems={itemsCustom}
              btnTitle={'EMPIEZA AHORA'}
              viewport={viewport}
            />
          </div>
        </div>
      ) : (
        <div id={id} className={'w-full flex flex-wrap justify-center price-plan'}>
          <p className={'w-full flex justify-center title'}>{router.locale === 'es' ? 'Un plan perfecto pensado para ti' : 'A perfect plan designed for you'}</p>
          <div className={'w-full flex flex-wrap plan-type'}>
            <button
              className={`btn-plan monthly ${btnPlan === 'monthly' ? 'active' : ''}`}
              onClick={() => handleClickPlan('monthly')}
            >
              MENSUAL
            </button>
            <button
              className={`btn-plan yearly ${btnPlan === 'yearly' ? 'active' : ''}`}
              onClick={() => handleClickPlan('yearly')}
            >
              ANUAL
            </button>
          </div>
          <div className={'w-full flex flex-wrap justify-center plan-cards-content'}>
            <Slider {...settings}>
              <PriceCard
                type={'normal'}
                title={'Basico'}
                price={currentPlan.basic.price}
                planUnit={'/ Mes'}
                planItems={itemsBasic}
                btnTitle={'PRUÉBALO AHORA'}
                viewport={viewport}
              />
              <PriceCard
                type={'main'}
                title={'Profesional'}
                price={currentPlan.pro.price}
                planUnit={'/ Mes'}
                planItems={itemsPro}
                btnTitle={'PRUÉBALO AHORA'}
                viewport={viewport}
              />
              <PriceCard
                type={'normal'}
                title={'Enterprise'}
                price={currentPlan.custom.price}
                planUnit={'/ Mes'}
                planItems={itemsCustom}
                btnTitle={'CONTACT NOW'}
                viewport={viewport}
              />
            </Slider>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
