import Image from 'next/image'
import React from 'react'

export default function ArticleCard(props) {
  const { image, title, onClick } = props

  const handleClick = blogID => {
    onClick(blogID)
  }

  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true)
  }, [])

  return mounted && (
    <div className={'article-item'} onClick={() => handleClick(1)}>
      <div className={'w-full flex flex-wrap'}>
        <div className={'card-img-content'}>
          <div className={'w-full card-img'}>
            <div className="">
              <Image src={image} layout="fill" objectFit="cover" alt=""/>
            </div>
          </div>
        </div>
      </div>
      <div className={'w-full flex-wrap card-content'}>
        <div className={'w-full flex flex-wrap card-content-wrap'}>
          <div className={'w-full flex flex-wrap card-content-background'}>
            <p className={'article-description'}>{title}</p>
            <button className={'btn-article-see-more'}>MAS INFORMACIÓN</button>
          </div>
        </div>
      </div>
    </div>
  )
}
