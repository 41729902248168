import React, { useState, Fragment } from 'react'
import Image from 'next/image'
import Slider from 'react-slick'
import { useSelector, useDispatch } from 'react-redux'

import HeadSection from '../components/layouts/HeadSection'
import Header from '../components/layouts/Header'
import Instruction from '../components/home/Instruction'
import Steps from '../components/home/Steps'
import BetaContact from '../components/home/BetaContact'
import PricePlan from '../components/home/PricePlan'
import Features from '../components/home/Features'
import CustomerSay from '../components/home/CustomerSay'
import HelpBlog from '../components/home/HelpBlog'
import Contact from '../components/home/Contact'
import Footer from '../components/layouts/Footer'

import { getHome } from '../lib/squidex'
import { useRouter } from 'next/router'
import { LogoJsonLd, NextSeo, SocialProfileJsonLd } from 'next-seo'

export default function Home({ data, viewport, utm }) {
  const router = useRouter()
  const dispatch = useDispatch()
  const menu = useSelector(state => state.menu)

  const [settings, setSettings] = useState({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  })

  React.useEffect(() => {
    const path = window.location.hash
    if (path && path.includes('#')) {
      setTimeout(() => {
        const id = path.replace('#', '')
        const el = window.document.getElementById(id)
        const r = el.getBoundingClientRect()
        window.top.scroll({
          top: pageYOffset + r.top,
          behavior: 'smooth',
        })
      }, 500)
    } else {
      if (menu == 'HOME') {
        const sectionPosition = 0
        window.scrollTo({
          top: sectionPosition,
          behavior: 'smooth',
        })
      }
    }
  })

  const homeContent = data.queryHomepageContents[0].data
  const commonContent = data.queryCommonContents[0].data
  const blogContent = data.queryBlogpostContents

  return (
    <Fragment>
      <NextSeo
        title={homeContent.seotitle[router.locale]}
        description={homeContent.seodescription[router.locale]}
        canonical={router.locale === 'es' ? 'https://playbak.io/' : 'https://playbak.io/en'}
        openGraph={{
          title: homeContent.seotitle[router.locale],
          description: homeContent.seodescription[router.locale],
          url: router.url,
        }}
        twitter={{
          handle: '@playbakvideo',
          site: '@playbakvideo',
          cardType: 'summary_large_image',
        }}
        languageAlternates={[
          {
            hrefLang: 'es',
            href: 'https://playback.io',
          },
          {
            hrefLang: 'en',
            href: 'https://playbak.io/en',
          },
        ]}
      />
      <div className={``}>
        <HeadSection />
        <LogoJsonLd logo="https://playbak.io/images/logo.png" url="https://www.playbak.io" />
        <SocialProfileJsonLd
          type="Organization"
          name="PLAYBAK"
          url="https://www.playbak.io"
          sameAs={[
            'https://www.facebook.com/PLAYBAKVideo/',
            'https://www.instagram.com/playbakvideo/',
            'https://www.linkedin.com/company/playbak',
            'https://twitter.com/playbakvideo',
          ]}
        />
        {viewport === 'desktop' ? (
          <React.Fragment>
            <main className={`main`}>
              <div className={'w-full flex flex-wrap video_list_image justify-center mt-16'}>
                <div className={'w-full flex video_list_image_content justify-center'}>
                  <Image src={'/images/hero_list.svg'} quality={100} layout="fill" objectFit="cover" alt="" />
                  <div className={'w-full flex flex-wrap justify-center video-intro'}>
                    <iframe
                      src="https://iframe.videodelivery.net/0cb9848e7e4f4366f002f8fc5343527b?autoplay=true&poster=https://upload.wikimedia.org/wikipedia/commons/c/cd/Black_from_a_camera.jpg"
                      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                      allowFullScreen={true}
                    />
                  </div>
                </div>
                <div className={'web-instruction'}>
                  <div className={'w-full grid grid-cols-4 gap-4'}>
                    {homeContent.herocards[router.locale] &&
                      homeContent.herocards[router.locale].map((card, idx) => {
                        return (
                          <Instruction
                            align={card.alignment}
                            imageWidth={40}
                            imageHeight={34}
                            icon={`https://cdn.gosuac.com/playbak/${card.icon[0].id}`}
                            title={card.title}
                            index={idx}
                            key={idx}
                            viewport={viewport}
                          >
                            <div
                              className={`${card.alignment === 'right' ? 'text-right' : ''} ${
                                card.alignment === 'center' ? 'text-center' : ''
                              } ${card.alignment === 'left' ? 'text-left' : ''}`}
                              dangerouslySetInnerHTML={{ __html: card.body }}
                            />
                          </Instruction>
                        )
                      })}
                  </div>
                </div>
              </div>
              <Header selectedMenu={menu} data={commonContent} viewport={viewport} />
              <Steps
                viewport={viewport}
                tagline={homeContent.tagline[router.locale]}
                steps={homeContent.steps[router.locale]}
              />
              <BetaContact
                title={commonContent.betatitle[router.locale]}
                subtitle={commonContent.betasubtitle[router.locale]}
                buttonText={commonContent.betasubmitbutton[router.locale]}
                thankYouText={commonContent.betathankyoutext[router.locale]}
                utm={utm}
                viewport={viewport}
              />
              <PricePlan id="pricing" viewport={viewport} />
              <Features
                id="features"
                header={homeContent.featuresheader[router.locale]}
                title={homeContent.featurestitle[router.locale]}
                body={homeContent.featuresbody[router.locale]}
                features={homeContent.features}
                viewport={viewport}
              />
              <div className={'w-full flex flex-wrap justify-center'}>
                <div className={'progress-img-content'}>
                  <div className={'w-full flex flex-wrap progress-img'}>
                    <Image src={'/images/progress.png'} layout={'fill'} alt={'Progress'} />
                  </div>
                </div>
              </div>
              {homeContent.showtestimonials.iv && <CustomerSay viewport={viewport} />}
              <HelpBlog data={blogContent} viewport={viewport} />
              <BetaContact
                title={commonContent.betatitle[router.locale]}
                subtitle={commonContent.betasubtitle[router.locale]}
                buttonText={commonContent.betasubmitbutton[router.locale]}
                thankYouText={commonContent.betathankyoutext[router.locale]}
                utm={utm}
                viewport={viewport}
              />
              <Contact
                id="contact"
                title={commonContent.contacttitle[router.locale]}
                subtitle={commonContent.contactsubtitle[router.locale]}
                location={commonContent.location[router.locale]}
                address={commonContent.address[router.locale]}
                phone={commonContent.phone[router.locale]}
                phoneNumber={commonContent.phonenumber[router.locale]}
                support={commonContent.support[router.locale]}
                supportEmail={commonContent.supportemail[router.locale]}
                getinTouchTitle={commonContent.getintouchtitle[router.locale]}
                getinTouchSubtitle={commonContent.getintouchsubtitle[router.locale]}
                nameinput={commonContent.nameinput[router.locale]}
                emailinput={commonContent.emailinput[router.locale]}
                subjectinput={commonContent.subjectinput[router.locale]}
                contactnowbutton={commonContent.contactnowbutton[router.locale]}
                viewport={viewport}
                thankYouText={commonContent.betathankyoutext[router.locale]}
                utm={utm}
              />
            </main>
            <Footer viewport={viewport} />
          </React.Fragment>
        ) : (
          <div className={`mobile`}>
            <main className={`main`}>
              <div className={'w-full flex flex-wrap video_list_image justify-center'}>
                <div className={'w-full flex video_list_image_content justify-center'}>
                  <Image src={'/images/hero_list.svg'} layout={'fill'} alt={'Hero List'} />
                  <div className={'flex flex-wrap justify-center video-intro'}>
                    <iframe
                      src="https://iframe.videodelivery.net/0cb9848e7e4f4366f002f8fc5343527b?autoplay=true&poster=https://upload.wikimedia.org/wikipedia/commons/c/cd/Black_from_a_camera.jpg"
                      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                      allowFullScreen={true}
                    />
                  </div>
                </div>
                <div className={'web-instruction'}>
                  <Slider {...settings}>
                    {homeContent.herocards[router.locale] &&
                      homeContent.herocards[router.locale].map((card, idx) => {
                        return (
                          <Instruction
                            align={card.alignment}
                            imageWidth={40}
                            imageHeight={34}
                            icon={`https://cdn.gosuac.com/playbak/${card.icon[0].id}`}
                            title={card.title}
                            index={idx}
                            key={idx}
                            viewport={viewport}
                          >
                            <div dangerouslySetInnerHTML={{ __html: card.body }} />
                          </Instruction>
                        )
                      })}
                  </Slider>
                </div>
              </div>
              <Header data={commonContent} selectedMenu={menu} viewport={viewport} />
              <Steps
                viewport={viewport}
                tagline={homeContent.tagline[router.locale]}
                steps={homeContent.steps[router.locale]}
              />
              <BetaContact
                title={commonContent.betatitle[router.locale]}
                subtitle={commonContent.betasubtitle[router.locale]}
                buttonText={commonContent.betasubmitbutton[router.locale]}
                thankYouText={commonContent.betathankyoutext[router.locale]}
                utm={utm}
                viewport={viewport}
              />
              <PricePlan id="pricing" viewport={viewport} />
              <Features
                id="features"
                header={homeContent.featuresheader[router.locale]}
                title={homeContent.featurestitle[router.locale]}
                body={homeContent.featuresbody[router.locale]}
                features={homeContent.features}
                viewport={viewport}
              />
              {homeContent.showtestimonials.iv && <CustomerSay viewport={viewport} />}
              <HelpBlog data={blogContent} viewport={viewport} />
              <BetaContact
                title={commonContent.betatitle[router.locale]}
                subtitle={commonContent.betasubtitle[router.locale]}
                buttonText={commonContent.betasubmitbutton[router.locale]}
                thankYouText={commonContent.betathankyoutext[router.locale]}
                utm={utm}
                viewport={viewport}
              />
              <Contact
                id="contact"
                title={commonContent.contacttitle[router.locale]}
                subtitle={commonContent.contactsubtitle[router.locale]}
                location={commonContent.location[router.locale]}
                address={commonContent.address[router.locale]}
                phone={commonContent.phone[router.locale]}
                phoneNumber={commonContent.phonenumber[router.locale]}
                support={commonContent.support[router.locale]}
                supportEmail={commonContent.supportemail[router.locale]}
                getinTouchTitle={commonContent.getintouchtitle[router.locale]}
                getinTouchSubtitle={commonContent.getintouchsubtitle[router.locale]}
                nameinput={commonContent.nameinput[router.locale]}
                emailinput={commonContent.emailinput[router.locale]}
                subjectinput={commonContent.subjectinput[router.locale]}
                contactnowbutton={commonContent.contactnowbutton[router.locale]}
                viewport={viewport}
                utm={utm}
              />
            </main>
            <Footer viewport={viewport} />
          </div>
        )}
      </div>
    </Fragment>
  )
}

export async function getStaticProps() {
  const data = await getHome()

  return {
    props: {
      data,
    },
  }
}
