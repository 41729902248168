import React, { useState, useRef } from 'react'
import Image from 'next/image'
import Slider from 'react-slick/lib'

export default function Steps({ tagline, steps, viewport }) {
  const [settings, setSettings] = useState({
    dots: true,
    infinite: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  })
  const slider = useRef(null);

  return (
    <React.Fragment>
      {viewport === 'desktop' ? (
        <div className={'w-full flex flex-wrap justify-center passion-title-content'}>
          <p className={'w-full flex justify-center'} dangerouslySetInnerHTML={{ __html: tagline }} />
          <div className={'steps-img'}>
            <Image src={'/images/steps.png'} layout={'fill'} alt=""/>
            <div className={'w-full flex justify-center grid grid-cols-5 gap-4 steps-texts'}>
              {steps &&
                steps.map((step, idx) => {
                  return <span key={idx}>{step.title}</span>
                })}
            </div>
          </div>
        </div>
      ) : (
        <div className={'w-full flex flex-wrap justify-center passion-title-content'}>
          <h2 className={'w-full flex justify-center'}>
            {'“Concéntrate en tu pasión'}
            <br />
            {' y nosotros hacemos todo lo demás”'}
          </h2>
          <div className={'w-full flex flex-wrap steps-slider-content'}>
            <span className={'icon-next-slider-arrow'} onClick={() => slider.current.slickNext()}>
              <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
                <defs>
                  <filter id="filter_1">
                    <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                  </filter>
                </defs>
                <g
                  id="icon--back-copy"
                  transform="matrix(-1 -2.1456883E-07 -2.1456883E-07 1 20 0)"
                  filter="url(#filter_1)"
                >
                  <g id="icon--back">
                    <path
                      d="M6.08851 0.242214L0.242449 6.08261C-0.0808163 6.40556 -0.0808163 6.92839 0.242449 7.25052L6.08851 13.0917C6.41177 13.4139 6.93429 13.4139 7.25755 13.0917C7.58082 12.7688 7.58082 12.246 7.25755 11.9238L1.99602 6.66656L7.25755 1.41095C7.58082 1.088 7.58082 0.565166 7.25755 0.242214C6.93429 -0.080738 6.41177 -0.080738 6.08851 0.242214"
                      transform="translate(5.833333 3.3333333)"
                      id="Fill-1"
                      fill="#333333"
                      fillRule="evenodd"
                      stroke="none"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <span className={'icon-previous-slider-arrow'} onClick={() => slider.current.slickPrev()}>
              <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
                <defs>
                  <filter id="filter_1">
                    <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                  </filter>
                </defs>
                <g
                  id="icon--back-copy"
                  transform="matrix(-1 -2.1456883E-07 -2.1456883E-07 1 20 0)"
                  filter="url(#filter_1)"
                >
                  <g id="icon--back">
                    <path
                      d="M6.08851 0.242214L0.242449 6.08261C-0.0808163 6.40556 -0.0808163 6.92839 0.242449 7.25052L6.08851 13.0917C6.41177 13.4139 6.93429 13.4139 7.25755 13.0917C7.58082 12.7688 7.58082 12.246 7.25755 11.9238L1.99602 6.66656L7.25755 1.41095C7.58082 1.088 7.58082 0.565166 7.25755 0.242214C6.93429 -0.080738 6.41177 -0.080738 6.08851 0.242214"
                      transform="translate(5.833333 3.3333333)"
                      id="Fill-1"
                      fill="#333333"
                      fillRule="evenodd"
                      stroke="none"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <Slider ref={slider} {...settings}>
              {steps &&
                steps.map((step, idx) => {
                  return (
                    <div className={'w-full flex flex-wrap steps-slider-item'} key={idx}>
                      <div className={'w-full flex flex-wrap justify-center'}>
                        <div className={'steps-img'}>
                          <Image src={`https://cdn.gosuac.com/playbak/${step.image[0].id}`} layout={'fill'} alt=""/>
                        </div>
                      </div>
                      <p className={'w-full flex justify-center step-title'}>{step.title}</p>
                    </div>
                  )
                })}
            </Slider>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
