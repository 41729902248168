import React from 'react'
import Image from 'next/image'

import ArticleCard from './ArticleCard'
import { useRouter } from 'next/router'

export default function HelpBlog({ data, viewport }) {
  const router = useRouter()

  const shorten = (str, maxLen, separator = ' ') => {
    if (str.length <= maxLen) return str
    return str.substr(0, str.lastIndexOf(separator, maxLen))
  }

  const handleClickArticle = blogId => {
    router.push('/blog/' + blogId)
  }

  return (
    <React.Fragment>
      {viewport === 'desktop' ? (
        <div className={'w-full flex flex-wrap help-blog-content'}>
          <div className={'w-full grid grid-cols-2 gap-4 header'}>
            <div className={`w-full flex flex-wrap title`}>
              <p>
                {router.locale === 'es' ? 'Nuestro' : 'Our'}
                <br />
                {'Blog'}
              </p>
              <div className={'question-img-content'}>
                <div className={'w-full flex flex-wrap question-img'}>
                  <Image src={'/images/question.png'} layout={'fill'} alt=""/>
                </div>
              </div>
            </div>
          </div>

          <div className={'w-full flex flex-wrap grid grid-cols-2 gap-8 help-section-3'}>
            <div className={'w-full help-first-section'}>
              <div
                className={'w-full flex flex-wrap justify-center'}
                onClick={() => handleClickArticle(data[0].data.slug[router.locale])}
              >
                <div className={'w-full help-blog-img-content'}>
                  <div className={'w-full flex flex-wrap help-blog-img'}>
                    <Image
                      src={`https://cms.gosuac.com/api/assets/playbak/${data[0].data.image.iv[0].id}`}
                      layout="fill"
                      objectFit="cover"
                      alt=""
                    />
                  </div>
                </div>
                <div className={'blog_hires'}>
                  <h1 className={'w-full flex flex-wrap justify-center blog-title'}>
                    {data[0].data.title[router.locale]}
                  </h1>
                  
                  <div
                    className={'w-full flex flex-wrap justify-center blog-description'}
                    dangerouslySetInnerHTML={{ __html: shorten(data[0].data.body[router.locale], 600) }}
                  />
                </div>
              </div>
            </div>
            <div className={'w-full help-second-section'}>
              <div className={'w-full flex flex-wrap justify-center'}>
                {data &&
                  data.slice(1, 3).map((post, idx) => {
                    return (
                      <ArticleCard
                        key={idx}
                        image={`https://cms.gosuac.com/api/assets/playbak/${post.data.image.iv[0].id}`}
                        title={post.data.title[router.locale]}
                        onClick={() => handleClickArticle(post.data.slug[router.locale])}
                      />
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={'w-full flex flex-wrap help-blog-content'}>
          <div className={'w-full header'}>
            <div className={`w-full flex flex-wrap title`}>
              <p>{router.locale === 'es' ? 'Nuestro Blog?' : 'Our Blog'}</p>
              <div className={'question-img-content'}>
                <div className={'w-full flex flex-wrap question-img'}>
                  <Image src={'/images/question.png'} layout={'fill'} alt=""/>
                </div>
              </div>
            </div>
          </div>

          <div className={'w-full flex flex-wrap help-section-3'}>
            <div className={'w-full help-first-section'}>
              <div
                className={'w-full flex flex-wrap justify-center'}
                onClick={() => handleClickArticle(data[0].data.slug[router.locale])}
              >
                <div className={'w-full help-blog-img-content'}>
                  <div className={'w-full flex flex-wrap help-blog-img'}>
                    <Image
                      src={`https://cms.gosuac.com/api/assets/playbak/${data[0].data.image.iv[0].id}?width=562&height=376&mode=Crop`}
                      width={562}
                      height={376}
                      alt=""
                    />
                  </div>
                </div>
                <div className={'w-full'}>
                  <div className={'w-full flex flex-wrap blog-description'}>
                    <h1>
                      <span>{data[0].data.title[router.locale]}</span>
                    </h1>
                    <p
                      className={'w-full flex flex-wrap justify-center blog-description-text'}
                      dangerouslySetInnerHTML={{ __html: shorten(data[0].data.body[router.locale], 600) }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'w-full flex flex-wrap justify-center help-section-4'}>
            {data &&
              data.slice(1, 2).map((post, idx) => {
                return (
                  <ArticleCard
                    key={idx}
                    image={`https://cms.gosuac.com/api/assets/playbak/${post.data.image.iv[0].id}?width=254&height=130&mode=Crop`}
                    title={post.data.title[router.locale]}
                    onClick={() => handleClickArticle(post.data.slug[router.locale])}
                  />
                )
              })}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
