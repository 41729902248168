import React from 'react'
import Image from 'next/image'
import Collapsible from 'react-collapsible'
import { useRouter } from 'next/router'

export default function Features({ header, title, body, features, id, viewport }) {
  const router = useRouter()

  return (
    <React.Fragment>
      {viewport === 'desktop' ? (
        <div id={id} className={`w-full flex flex-wrap functionalies-content`}>
          <div className={`w-full flex flex-wrap title`}>
            <p>{header}</p>
            <div className={'setting-img-content'}>
              <div className={'w-full flex flex-wrap setting-img'}>
                <Image src={'/images/setting.png'} layout={'fill'} alt=""/>
              </div>
            </div>
          </div>
          <div className={'w-full flex flex-wrap grid grid-cols-2 gap-8 collapise-content'}>
            <div className={'w-full first-column'}>
              <p className={'description-content'}>
                <span className={'bold italic'}>{title}</span>
                <br />
                <br />
                {body}
              </p>
              {features.iv.slice(0, 9).map((item, key) => {
                return (
                  <Collapsible trigger={item.data.title[router.locale]} key={key} lazyRender={true}>
                    <div className={'w-full flex flex-row '}>
                      <p dangerouslySetInnerHTML={{ __html: item.data.body[router.locale] }} />
                    </div>
                  </Collapsible>
                )
              })}
            </div>
            <div className={'w-full second-column'}>
              {features.iv.slice(12).map((item, key) => {
                return (
                  <Collapsible trigger={item.data.title[router.locale]} key={key} lazyRender={true}>
                    <div className={'w-full flex flex-row '}>
                      <p dangerouslySetInnerHTML={{ __html: item.data.body[router.locale] }} />
                    </div>
                  </Collapsible>
                )
              })}
            </div>
          </div>
        </div>
      ) : (
        <div id={id} className={`w-full flex flex-wrap functionalies-content`}>
          <div className={`w-full flex flex-wrap title`}>
            <p>{header}</p>
          </div>
          <div className={'w-full flex flex-wrap collapise-content'}>
            <div className={'w-full first-column'}>
              <div className={'description-content'}>
                <span className={'bold italic'}>{title}</span>
                <br />
                <br />
                {body}
                <div className={'setting-img-content'}>
                  <div className={'w-full flex flex-wrap setting-img'}>
                    <Image src={'/images/setting.png'} layout={'fill'} alt=""/>
                  </div>
                </div>
              </div>
              {features.iv.map((item, key) => {
                return (
                  <Collapsible trigger={item.data.title[router.locale]} key={key} lazyRender={true}>
                    <div className={'w-full flex flex-row '}>
                      <p dangerouslySetInnerHTML={{ __html: item.data.body[router.locale] }} />
                    </div>
                  </Collapsible>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
