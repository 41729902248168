import React from 'react'
import Image from 'next/image'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import googleMapStyles from '../../commons/GoogleMapData.json'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { request } from '../../lib/forms'
import * as gtag from '../../lib/gtag'
import * as fbp from '../../lib/fbp'
import Loader from 'react-loader-spinner'

const center = {
  lat: 40.5168,
  lng: -1.7058,
}

export default function Contact({
  title,
  subtitle,
  location,
  address,
  phone,
  phoneNumber,
  support,
  supportEmail,
  getinTouchTitle,
  getinTouchSubtitle,
  contactnowbutton,
  subjectinput,
  emailinput,
  nameinput,
  id,
  viewport,
  utm,
  thankYouText,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [mounted, setMounted] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false)
  const [showForm, setShowForm] = React.useState(true)
  const [showLoading, setShowLoading] = React.useState(false)
  const router = useRouter()
  const [warningState, setWarningState] = React.useState(false)

  React.useEffect(() => {
    setMounted(true)
  }, [])

  const containerStyle = {
    width: '100%',
    height: viewport === 'desktop' ? '833px' : '73px',
  }

  const onSubmit = async data => {
    setShowLoading(true)
    setShowForm(false)
    utm.setConversion()
    await request(
      'POST',
      'https://internal-api.gosuac.com/api/v1/leads',
      JSON.stringify({
        name: data.name,
        email: data.email,
        language: data.lang,
        project_id: '1',
        client_id: '1',
        extra_data: {
          page_name: 'Home',
          page_url: router.pathname,
          hutk: document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1'),
        },
        UTMS: utm.history || [],
      })
    )
    gtag.event({ action: 'lead', category: 'generate_lead', label: 'form', value: 1 })
    gtag.adwordsLead()
    fbp.event({ event: 'Lead', data: {} })
    setShowResults(true)
    setShowLoading(false)
  }

  return mounted && (
    <React.Fragment>
      {viewport === 'desktop' ? (
        <div id={id} className={`w-full flex flex-wrap contact-content`}>
          <div className={'w-2/5 map-content'}>
            <LoadScript googleMapsApiKey="AIzaSyCrqp_iYoLmO8ZVhAk6zKe8Ew5fDCYnPAU">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12.5}
                options={{
                  fullscreenControl: false,
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  styles: googleMapStyles,
                }}
              />
            </LoadScript>
            <div className={'w-full map-overlay'}></div>
            <div className={'w-full location-content'}>
              <p className={'title-header'}>{title}</p>
              <p className={'sub-title-header'}>{subtitle}</p>
              <div className={'w-full flex flex-wrap'}>
                <div className={'place-icon-content'}>
                  <div className={'w-full flex flex-wrap place-icon'}>
                    <i className="fal fa-map-marker-alt"></i>
                  </div>
                </div>
              </div>
              <p className={'contact-title'}>{location}</p>
              <p className={'contact-sub-title'}>{address}</p>
              <div className={'w-full flex flex-wrap'}>
                <div className={'w-full phone-icon-content'}>
                  <div className={'w-full flex flex-wrap phone-icon'}>
                    <i className="fal fa-phone-alt"></i>
                  </div>
                </div>
              </div>
              <p className={'contact-title'}>{phone}</p>
              <p className={'contact-sub-title'}>{phoneNumber}</p>
              <div className={'w-full flex flex-wrap'}>
                <div className={'envelope-icon-content'}>
                  <div className={'w-full flex flex-wrap envelope-icon'}>
                    <i className="fal fa-envelope"></i>
                  </div>
                </div>
              </div>
              <p className={'contact-title'}>
                <a href="mailto:{supportEmail}" target="_blank" rel="noreferrer">
                  {support}
                </a>
              </p>
              <p className={'contact-sub-title'}>
                <a href="mailto:{supportEmail}" target="_blank" rel="noreferrer">
                  {supportEmail}
                </a>
              </p>
            </div>
          </div>
          <div className={'w-3/5 form-content'}>
            <p className={'contact-title'}>{getinTouchTitle}</p>
            <p className={'contact-sub'}>{getinTouchSubtitle}</p>
            <div className={'w-full flex flex-wrap contact-form-content'}>
              {showLoading && <Loader type="Grid" color="#000" height={200} width={200} />}

              {showResults && thankYouText}

              {showForm && (
                <form onSubmit={handleSubmit(onSubmit)} className={'w-full'}>
                  <div className={'w-full flex flex-wrap input-group'}>
                    <input
                      {...register('name', {
                        required: true,
                      })}
                      placeholder={nameinput}
                      type={'text'}
                      name={'name'}
                      id={id}
                    />
                    {errors.name && <span className={'warning-msg'}>Nombre Necesario</span>}
                    {errors.name && <i className={'fal fa-warning warning-icon'} />}
                  </div>

                  <div className={'w-full flex flex-wrap input-group'}>
                    <input
                      {...register('email', {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'invalid email address',
                        },
                      })}
                      placeholder={emailinput}
                      type={'email'}
                      name={'email'}
                      id={id}
                    />
                    {errors.email && <span className={'warning-msg'}>Email Necesario</span>}
                    {errors.email && <i className={'fal fa-warning warning-icon'} />}
                  </div>

                  <div className={'w-full flex flex-wrap input-group'}>
                    <input {...register('message')} placeholder={subjectinput} type={'text'} name={'message'} id={id} />
                  </div>

                  <button type="submit" className={'btn-contact'}>
                    {contactnowbutton}
                  </button>

                  <input
                    {...register('lang')}
                    name="lang"
                    style={{ display: 'none' }}
                    defaultValue={router.locale}
                    tabIndex="-1"
                    autoComplete="false"
                  />

                  <input
                    {...register('fax_number')}
                    id="fax_number"
                    name="fax_number"
                    placeholder="Su Numero de fax"
                    defaultValue=""
                    tabIndex="-1"
                    autoComplete="false"
                    style={{ display: 'none' }}
                  />
                  <input
                    {...register('second_name')}
                    id="second_name"
                    name="second_name"
                    placeholder="Segundo Nombre"
                    defaultValue=""
                    tabIndex="-1"
                    autoComplete="false"
                    style={{ display: 'none' }}
                  />
                </form>
              )}
            </div>

          <div className={'contact-img-content'}>
              <div className={'w-full flex flex-wrap contact-img'}>
                <Image src={'/images/contact.png'} layout={'fill'} alt=""/>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id={id} className={`w-full flex flex-wrap contact-content`}>
          <div className={'w-full flex flex-wrap form-content'}>
            <div className={'contact-img-content'}>
              <div className={'w-full flex flex-wrap contact-img'}>
                <Image src={'/images/contact.png'} layout={'fill'} alt=""/>
              </div>
            </div>
            <p className={'contact-title'}>{title}</p>
            <p className={'contact-sub'}>{subtitle}</p>
            <div className={'w-full flex flex-wrap contact-form-content'}>
              {showLoading && <Loader type="Grid" color="#fff" height={200} width={200} />}

              {showResults && thankYouText}

              {showForm && (
                <form onSubmit={handleSubmit(onSubmit)} className={'w-full text-center'}>
                  <div className={'w-full flex flex-wrap input-group'}>
                    <input
                      {...register('name', {
                        required: true,
                      })}
                      placeholder={nameinput}
                      type={'text'}
                      name={'name'}
                      id={id}
                    />
                    {warningState && <span className={'warning-msg'}>Incorrect</span>}
                    {warningState && <i className={'fal fa-warning warning-icon'}></i>}
                  </div>

                  <div className={'w-full flex flex-wrap input-group'}>
                    <input
                      {...register('email', {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'invalid email address',
                        },
                      })}
                      placeholder={emailinput}
                      type={'email'}
                      name={'email'}
                      id={id}
                    />
                    {warningState && <span className={'warning-msg'}>Incorrect</span>}
                    {warningState && <i className={'fal fa-warning warning-icon'}></i>}
                  </div>

                  <div className={'w-full flex flex-wrap input-group'}>
                    <input
                      {...register('message', {
                        required: true,
                      })}
                      placeholder={subjectinput}
                      type={'text'}
                      name={'message'}
                      id={id}
                    />
                    {warningState && <span className={'warning-msg'}>Incorrect</span>}
                    {warningState && <i className={'fal fa-warning warning-icon'}></i>}
                  </div>
                  <button type="submit" className={'btn-contact'}>
                    {contactnowbutton}
                  </button>

                  <input
                    {...register('lang')}
                    name="lang"
                    style={{ display: 'none' }}
                    defaultValue={router.locale}
                    tabIndex="-1"
                    autoComplete="false"
                  />

                  <input
                    {...register('fax_number')}
                    id="fax_number"
                    name="fax_number"
                    placeholder="Su Numero de fax"
                    defaultValue=""
                    tabIndex="-1"
                    autoComplete="false"
                    style={{ display: 'none' }}
                  />
                  <input
                    {...register('second_name')}
                    id="second_name"
                    name="second_name"
                    placeholder="Segundo Nombre"
                    defaultValue=""
                    tabIndex="-1"
                    autoComplete="false"
                    style={{ display: 'none' }}
                  />
                </form>
              )}
            </div>
          </div>
          <div className={'w-full flex flex-wrap map-content'}>
            <LoadScript googleMapsApiKey="AIzaSyCrqp_iYoLmO8ZVhAk6zKe8Ew5fDCYnPAU">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10.5}
                options={{
                  fullscreenControl: false,
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  styles: googleMapStyles,
                }}
              />
            </LoadScript>
            <div className={'w-full map-overlay'}></div>
            <div className={'w-full contact-info-content'}>
              <div className={'w-full flex flex-wrap grid grid-cols-3 gap-4'}>
                <div className={'w-full flex flex-wrap justify-center place-icon-content'}>
                  <div className={'w-full flex flex-wrap place-icon'}>
                    <Image src={'/images/place_mobile.png'} layout={'fill'} alt=""/>
                  </div>
                  <p className="w-full flex flex-wrap justify-center">{location}</p>
                </div>

                <div className={'w-full flex flex-wrap justify-center phone-icon-content'}>
                  <div className={'w-full flex flex-wrap phone-icon'}>
                    <Image src={'/images/phone_mobile.png'} layout={'fill'} alt=""/>
                  </div>
                  <p className="w-full flex flex-wrap justify-center">
                    <a href="tel:{phoneNumber}">{phoneNumber}</a>
                  </p>
                </div>

                <div className={'w-full flex flex-wrap justify-center envelope-icon-content'}>
                  <div className={'w-full flex flex-wrap envelope-icon'}>
                    <Image src={'/images/envelope_mobile.png'} layout={'fill'} alt=""/>
                  </div>
                  <p className="w-full flex flex-wrap justify-center">
                    <a href="mailto:{supportEmail}" target="_blank" rel="noreferrer">
                      {support}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
