import React from 'react'
import Image from 'next/image'

export default function Instruction({ align, icon, imageWidth, imageHeight, title, children, index, viewport }) {
  return (
    <div
      className={`w-full item ${viewport === 'mobile' ? 'flex flex-wrap justify-center' : ''} ${
        viewport === 'desktop' ? 'index-' + index : ''
      }`}
    >
      <div
        className={`w-full flex flex-wrap ${viewport === 'mobile' ? 'justify-center' : ''} ${
          align === 'center' ? 'justify-center' : align === 'left' ? '' : 'justify-end'
        }`}
      >
        <div className={`w-full flex flex-wrap logo`}>
          <Image src={icon} width={imageWidth} height={imageHeight} alt=""/>
        </div>
      </div>
      <p className={`title ${align === 'center' ? 'text-center' : align === 'left' ? '' : 'text-right'}`}>{title}</p>
      <div
        className={`w-full flex flex-wrap ${viewport === 'mobile' ? 'justify-center' : ''} underline-shape ${
          align === 'center' ? 'justify-center' : align === 'left' ? '' : 'justify-end'
        }`}
      >
        <span></span>
      </div>
      <div className={`flex ${align === 'right' ? 'justify-end' : ''}`}>
        <label className={`description`}>{children}</label>
      </div>
    </div>
  )
}
